import React from 'react'
import { Link } from 'gatsby'

const BubbleLinks = ({title, link, image, altText}) => {
    return (
        <Link to={link} className='w-full sm:w-64 m-2 drop-shadow-lg'>
            <div className='flex sm:flex-col sm:h-80 items-center mx-2 bg-orange-100 hover:bg-orange-300 p-2 border-4 border-blue-600 rounded-lg text-xl hover:font-bold '>
                <div className='flex h-32 w-32 rounded-full bg-white justify-center sm:mt-4 mx-4 border-gray-500 border-2 drop-shadow-lg'>
                    <img src={image} alt={altText} className="object-scale-down h-32" />
                </div>
                <p className='text-2xl w-32 sm:w-full sm:mt-8 sm:text-center ml-4 sm:ml-0'>{title}</p>
                {/* <div className='text-2xl w-32 sm:w-full justify-center sm:mt-8 sm:text-center ml-4 sm:ml-0'>
                    <text>{title}</text>
                </div> */}
            </div>
        </Link>
    )
}

export default BubbleLinks