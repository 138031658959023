import * as React from "react"
import { Link } from 'gatsby'

import NavBar from "../components/NavBar"
import BubbleLinks from "../components/BubbleLinks"

import ToothBuddyLogo from '../images/tooth_buddy_logo.png'
//import ToothBuddyHockey from '../images/tooth_buddy_hockey.jpg'

// markup

const bubbleListings = [
	{
		title: "Just For Kids",
		link: "forkids",
		image: ToothBuddyLogo,
		altText: "Picture of kids"
	},
	{
		title: "Parents, Family, Friends, Caregivers",
		link: "foradults",
		image: ToothBuddyLogo,
		altText: "Picture of parents, family, friends, caregivers"
	},
	{
		title: "Dental Professionals",
		link: "dentalprofessionals",
		image: ToothBuddyLogo,
		altText: "Picture of dental professionals"
	},
	{
		title: "Educators",
		link: "educators",
		image: ToothBuddyLogo,
		altText: "Picture of dental professionals"
	}
]

const IndexPage = () => {

	return (

    <main>
		<NavBar />
		<div>
			<p className="text-5xl font-semibold pt-10 text-center">Tooth Buddy and the Golden Toothbrush, Available Now!</p>
			{/* <div className="flex flex-row pt-10 justify-center">
				<text className="text-5xl font-semibold">Tooth Buddy and the Golden Toothbrush, Available now!</text>
			</div> */}
			<div className="flex flex-row pt-6 justify-center text-xl ">
				<Link to="shop" className="bg-orange-300 p-2 px-6 rounded-md">
					Shop
				</Link>
			</div>
			{/* <div className="flex flex-row justify-center pt-8 space-x-10">
				<div className="border-white border-solid border-8 rounded-3xl bg-blue-500 drop-shadow-lg">
					<img src={ToothBuddyLogo} alt="Tooth Buddy" className="h-64 justify-center"/>
				</div>
				<div className="border-white border-solid border-8 rounded-3xl bg-blue-500 drop-shadow-lg">
					<img src={ToothBuddyHockey} alt="Tooth Buddy" className="h-64 justify-center rounded-2xl"/>
				</div>
			</div> */}
			{/*<img src={ToothBuddyHockey} alt="tooth buddy hockey"/>	*/}
			<div className="flex flex-col sm:flex-row items-center sm:justify-center mt-4 mb-96 py-2">
				{bubbleListings.map((data) => (
					<BubbleLinks title={data.title} link='underconstruction' image={data.image} altText={data.altText} />
				))}	
			</div>	
		</div>
    </main>

		// <main style={pageStyles}>
		// <NavBar />
		// <title>Home Page</title>
		// <h1 style={headingStyles}>
		// 	Congratulations
		// 	<br />
		// 	<span style={headingAccentStyles}>— you just made a Gatsby site! </span>
		// 	<span role="img" aria-label="Party popper emojis">
		// 	🎉🎉🎉
		// 	</span>
		// </h1>
		// <p style={paragraphStyles}>
		// 	Edit <code style={codeStyles}>src/pages/index.js</code> to see this page
		// 	update in real-time.{" "}
		// 	<span role="img" aria-label="Sunglasses smiley emoji">
		// 	😎
		// 	</span>
		// </p>
		// <ul style={listStyles}>
		// 	<li style={docLinkStyle}>
		// 	<a
		// 		style={linkStyle}
		// 		href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
		// 	>
		// 		{docLink.text}
		// 	</a>
		// 	</li>
		// 	{links.map(link => (
		// 	<li key={link.url} style={{ ...listItemStyles, color: link.color }}>
		// 		<span>
		// 		<a
		// 			style={linkStyle}
		// 			href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
		// 		>
		// 			{link.text}
		// 		</a>
		// 		{link.badge && (
		// 			<span style={badgeStyle} aria-label="New Badge">
		// 			NEW!
		// 			</span>
		// 		)}
		// 		<p style={descriptionStyle}>{link.description}</p>
		// 		</span>
		// 	</li>
		// 	))}
		// </ul>
		// <img
		// 	alt="Gatsby G Logo"
		// 	src="data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2a10 10 0 110 20 10 10 0 010-20zm0 2c-3.73 0-6.86 2.55-7.75 6L14 19.75c3.45-.89 6-4.02 6-7.75h-5.25v1.5h3.45a6.37 6.37 0 01-3.89 4.44L6.06 9.69C7 7.31 9.3 5.63 12 5.63c2.13 0 4 1.04 5.18 2.65l1.23-1.06A7.959 7.959 0 0012 4zm-8 8a8 8 0 008 8c.04 0 .09 0-8-8z' fill='%23639'/%3E%3C/svg%3E"
		// />
		// </main>
	)
}

export default IndexPage
